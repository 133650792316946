<template>
    <client-page>

        <sub-visual
        sh="후원하기"
        tabActive="정기후원"
        bg="/images/sub/visual/sv3.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <!-- 결연후원 -->
                <donation-form code="정기후원"/>
            </v-container>
        </section>
        
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import DonationForm from "@/components/client/support/donation-form.vue"

export default {
    components: {
        ClientPage,
        SubVisual,
        DonationForm
    },
    data() {
        return {
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        async init(){
        }
    },
    watch:{
        code(){
        }
    }
}
</script>

<style>

</style>